@import "constants";

.social-links {
  display: flex;
  align-items: center;
  gap: 20px;

  .discord-btn {
    height: 50px;
    width: 180px;
    font-size: 14px;
    border: 1px solid white;
    margin-right: 30px;
    transition: $animation-time all;

    &:hover {
      color: $black;
      background-color: white;
    }
  }
}
