.social-links {
  align-items: center;
  gap: 20px;
  display: flex;
}

.social-links .discord-btn {
  height: 50px;
  width: 180px;
  border: 1px solid #fff;
  margin-right: 30px;
  font-size: 14px;
  transition: all .3s;
}

.social-links .discord-btn:hover {
  color: #000406;
  background-color: #fff;
}

/*# sourceMappingURL=index.c0dd8e6b.css.map */
